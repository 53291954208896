<template>
  <div>
    <div id="home" v-if="!loading">
      <h1 id="title" v-if="title">{{ title }}</h1>
      <div>
        This is a <a target="_blank" href="https://docs.github.com/en/github/creating-cloning-and-archiving-repositories/creating-a-repository-on-github/creating-a-template-repository">template repo</a> that should be used to build our SPA (single page app) projets
      </div>
      <Button>
        Component test
      </Button>
    </div>
    <div v-else>
      Loading
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Button from './components/button';

export default {
  name: 'App',
  components: {
    Button
  },
  data: () => ({
    loading: true
  }),
  async mounted () {
    await this.fetchTitle();
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      title: 'example/getTitle'
    })
  },
  methods: {
    ...mapActions({
      fetchTitle: 'example/fetchTitle'
    })
  }
};

</script>

<style>
  * {
    box-sizing: border-box;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  #fetchOutput{
    width :40rem;
    height:20rem;
  }
</style>